import React, { useContext, useState, useEffect } from 'react';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import PortfolioContext from '../../context/context';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  // componentDidMoun() {
  // const venuesPopUp = ReactTypeformEmbed.makePopup('https://nickrollinson.typeform.com/to/h06ebq', {
  //   mode: 'popup',
  //   autoClose: 3000,
  //   hideHeaders: true,
  //   hideFooters: true,
  //   onSubmit() {
  //     console.log('Typeform successfully submitted');
  //   },
  // });
  // document.getElementById('bt-popup').addEventListener('click', function() {
  //   venuesPopUp.open();
  // });
  // }

  // const embededForm = () => (
  //   <ReactTypeformEmbed url="https://nickrollinson.typeform.com/to/h06ebq" />
  // );

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <h1 className="hero-title">
            {title || 'Welcome to'} <span className="text-color-main">{name || 'GigAux'}</span>
            <br />
            {subtitle || 'Matchmaking platform for musicians and venues'}
          </h1>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <p className="hero-cta">
            <a className="cta-btn cta-btn--hero" href="https://nickrollinson.typeform.com/to/h06ebq">
              {cta || 'Venues Apply'}
            </a>
          </p>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <p className="hero-cta">
            <a className="cta-btn cta-btn--hero" href="https://nickrollinson.typeform.com/to/gGxb0Q">
              {cta || 'Musicians Apply'}
            </a>
          </p>
        </Fade>
      </Container>
    </section>
  );
};

export default Header;
